import React from "react";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material/styles";

interface Props {
  teksti: string;
  url: string;
}

const ProtectedHeaderLink = ({ teksti, url }: Props): JSX.Element => {
  const navigate = useNavigate();
  const theme = useTheme();

  const handleLink = () => {
    navigate(url);
  };

  return (
    <Button
      variant="text"
      onClick={handleLink}
      size="small"
      sx={{
        justifyContent: "center",
        alignItems: "flex-center",
        minWidth: "100px",
        maxWidth: "100px",
        minHeight: "65px",
        maxHeight: "65px",
        color: theme.palette.text.primary,
        textTransform: "uppercase",
        marginLeft: "25px",
        "&:active": {
          color: theme.palette.primary.main,
        },
        "&:hover": {
          color: theme.palette.primary.main,
        },
      }}
    >
      {teksti}
    </Button>
  );
};

export default ProtectedHeaderLink;

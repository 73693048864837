import React, { createContext, useState, useContext, useEffect, useCallback } from "react";

import axiosInstance from "./axiosInstance";

interface AuthContextData {
  isAuthenticated: boolean;
  login: (tokens: { access_token: string; refresh_token: string }) => void;
  logout: () => void;
  refreshToken: () => Promise<void>;
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

export const useAuth = () => useContext(AuthContext);

interface AuthProviderProps {
  children: React.ReactNode;
}
export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);

  const login = useCallback(async (tokens: { access_token: string; refresh_token: string }) => {
    localStorage.setItem("access_token", tokens.access_token);
    localStorage.setItem("refresh_token", tokens.refresh_token);
    setIsAuthenticated(true);
  }, []);

  const logout = useCallback(() => {
    localStorage.removeItem("access_token");
    localStorage.removeItem("refresh_token");
    setIsAuthenticated(false);
  }, []);

  const refreshToken = useCallback(async () => {
    const refresh_token = localStorage.getItem("refresh_token");
    if (!refresh_token) {
      logout();
      return;
    }
    try {
      const response = await axiosInstance.post("/auth/token/refresh/", { refresh: refresh_token });
      localStorage.setItem("access_token", response.data.access_token);
      localStorage.setItem("refresh_token", response.data.refresh_token || refresh_token); // Fallback to old refresh token if not updated
      setIsAuthenticated(true);
    } catch (error) {
      logout();
    }
  }, [logout]);


  useEffect(() => {
    const verifyToken = async () => {
      const access_token = localStorage.getItem("access_token");
      if (!access_token) {
        setIsAuthenticated(false);
        setLoading(false);
        return;
      }
      try {
        await axiosInstance.get("/verify-token/");
        setIsAuthenticated(true);
      } catch (error) {
        refreshToken();
      } finally {
        setLoading(false);
      }
    };

    verifyToken();
  }, [refreshToken]);


  if (loading) {
    return <div>Loading...</div>; // Optionally return a loading spinner
  }


  return (
    <AuthContext.Provider value={{ isAuthenticated, login, logout, refreshToken }}>{children}</AuthContext.Provider>
  );
};

export default AuthProvider;

const baseApiUrl =
  process.env.NODE_ENV === "production"
    ? "https://8plusnps.positify.fi/api"
    : "http://localhost/api";

const tilausApiUrl =
  process.env.NODE_ENV === "production"
    ? "https://8plusnps.positify.fi/api/tilaus/"
    : "http://localhost/api/tilaus/";

const jalleenmyyjaApiUrl =
  process.env.NODE_ENV === "production"
    ? "https://8plusnps.positify.fi/api/jalleenmyyja/"
    : "http://localhost/api/jalleenmyyja/";

const vastausajanPidennysApiUrl =
  process.env.NODE_ENV === "production"
    ? "https://8plusnps.positify.fi/api/vastausajan-muuttaminen/"
    : "http://localhost/api/vastausajan-muuttaminen/";

const psfDataApiUrl =
  process.env.NODE_ENV === "production"
    ? "https://8plusnps.positify.fi/api/8plusnpsdata/"
    : "http://localhost/api/8plusnpsdata/";

const liidiApiUrl =
  process.env.NODE_ENV === "production"
    ? "https://8plusnps.positify.fi/api/kiitossivun-yhteydenotto/"
    : "http://localhost/api/kiitossivun-yhteydenotto/";

const viesCheck =
  process.env.NODE_ENV === "production"
    ? "https://8plusnps.positify.fi/api/vies-check/"
    : "http://localhost/api/vies-check/";

const config = {
  baseApiUrl,
  tilausApiUrl,
  jalleenmyyjaApiUrl,
  vastausajanPidennysApiUrl,
  psfDataApiUrl,
  liidiApiUrl,
  viesCheck
};

export default config;

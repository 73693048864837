import React from 'react';
import { useTheme } from "@mui/material/styles";
import { SxProps } from "@mui/material";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { TilaajaData } from './tauluInterfaces';

interface TilaajatauluComponentProps {
  data: TilaajaData[];
  otsikko: string;
  otsikkoSx?: SxProps;
}

const TilaajatauluComponent: React.FC<TilaajatauluComponentProps> = ({ data, otsikko, otsikkoSx }) => {
  const theme = useTheme();
  return (
    <>
      <Typography variant="h5" sx={{ mb: 1, pl: 2, ...otsikkoSx }}>{otsikko}</Typography>
      <TableContainer>
        <Table
          sx={{ minWidth: 650 }}
          size="medium"
          aria-label="simple table"
        >
          <TableHead>
            <TableRow sx={{ backgroundColor: theme.palette.background.paper }}>
              <TableCell>#</TableCell>
              <TableCell>ID</TableCell>
              <TableCell>Yrityksen nimi</TableCell>
              <TableCell>Y-tunnus</TableCell>
              <TableCell>Markkinointinimi</TableCell>
              <TableCell>Tilattu</TableCell>
              <TableCell>Kysely päättyy</TableCell>
              <TableCell>Päätoimiala</TableCell>
              <TableCell>Liikevaihdon kokoluokka</TableCell>
              <TableCell>Postinumero</TableCell>
              <TableCell>Tilaajan nimi</TableCell>
              <TableCell>Sähköposti</TableCell>
              <TableCell>Puhelin</TableCell>
              <TableCell>Tilatut tutkimukset</TableCell>
              <TableCell>Hinta</TableCell>
              <TableCell>Tilauskoodi</TableCell>
              <TableCell>Vertailuraporttikoodi</TableCell>
              <TableCell>Ulkoinen tutkimus auki?</TableCell>
              <TableCell>Sisäinen tutkimus auki?</TableCell>
              <TableCell>Kampanjakoodi</TableCell>
              <TableCell>Jälleenmyyjä</TableCell>
              <TableCell sx={{ fontWeight: 900, width: "600px" }}>Muut huomiot</TableCell>
              {/* <TableCell>Asiakas -raporttipaketti zip</TableCell> */}
              {/* <TableCell>Henkilöstö -raporttipaketti zip</TableCell> */}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row: TilaajaData, index: number) => (
              <TableRow key={index} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                <TableCell component="th" scope="row">{index + 1}</TableCell>
                <TableCell>{row.pk}</TableCell>
                <TableCell>{row.yrityksen_nimi}</TableCell>
                <TableCell>{row.y_tunnus}</TableCell>
                <TableCell>{row.markkinointi_nimi}</TableCell>
                <TableCell>{row.tilaus_pvm}</TableCell>
                <TableCell>{row.kyselyn_paattymis_pvm}</TableCell>
                <TableCell>{row.formatted_paatoimiala}</TableCell>
                <TableCell>{row.formatted_kokoluokka}</TableCell>
                <TableCell>{row.yrityksen_postinumero}</TableCell>
                <TableCell>{row.tilaajan_nimi}</TableCell>
                <TableCell>{row.yhteys_sposti}</TableCell>
                <TableCell>{row.yhteys_puh}</TableCell>
                <TableCell>{row.formatted_tilatut_tuotteet.join(", ")}</TableCell>
                <TableCell>{row.hinta}</TableCell>
                <TableCell>{row.tilauskoodi}</TableCell>
                <TableCell>{row.vertailuraporttikoodi}</TableCell>
                <TableCell>{row.is_asty_open}</TableCell>
                <TableCell>{row.is_hr_open}</TableCell>
                <TableCell>{row.kampanjakoodi}</TableCell>
                <TableCell>{row.jalleenmyyja}</TableCell>
                <TableCell>{row.muuta}</TableCell>
                {/* <TableCell>
                      <button
                        onClick={() => downloadZip(row.y_tunnus, "VUOSIPALAUTE - ASIAKKAAT.zip", 1)}
                        style={{ textDecoration: "none", color: "black" }}
                      >
                        asty-zip
                      </button>
                    </TableCell> */}

                {/* <TableCell>
                      <button
                        onClick={() => downloadZip(row.y_tunnus, "VUOSIPALAUTE - Henkilöstö.zip", 2)}
                        style={{ textDecoration: "none", color: "black" }}
                      >
                        hr-zip
                      </button>
                    </TableCell> */}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default TilaajatauluComponent;
import React from "react";
import Button from "@mui/material/Button";
import { useAuth } from "../../Unprotected/Login/AuthContext";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material/styles";

const LogoutButton: React.FC = () => {
  const { logout } = useAuth();
  const navigate = useNavigate();
  const theme = useTheme();

  const handleLogout = () => {
    logout();
    navigate("/kirjautuminen");
  };

  return (
    <Button
      variant="text"
      onClick={handleLogout}
      size="small"
      sx={{
        justifyContent: "center",
        alignItems: "flex-center",
        minWidth: "100px",
        maxWidth: "100px",
        minHeight: "65px",
        maxHeight: "65px",
        color: theme.palette.text.primary,
        textTransform: "uppercase",
        marginLeft: "25px",
        "&:active": {
          color: theme.palette.primary.main,
        },
        "&:hover": {
          color: theme.palette.primary.main,
        },
      }}
    >
      Ulos
    </Button>
  );
};

export default LogoutButton;

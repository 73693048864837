import React from "react";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useState, useEffect } from "react";
import axiosInstance from "../../Unprotected/Login/axiosInstance";
import { useAuth } from "../../Unprotected/Login/AuthContext";
import { TilaajaData } from "../tauluInterfaces";
import TilaajatauluComponent from "../TilaajatauluComponent";


const Tilaajataulu: React.FC = () => {
  const [data, setData] = useState<TilaajaData[]>([]);
  const [error, setError] = useState<null | string>(null);
  const theme = useTheme();
  const { refreshToken } = useAuth();

  useEffect(() => {
    const fetchData = async () => {
      try {
        await refreshToken();
        const response = await axiosInstance.get("/tilaajataulu/");
        setData(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
        setError("Virhe: datan hakeminen epäonnistui.");
      }
    };

    fetchData();
  }, []);

  // async function downloadZip(y_tunnus: string, zip_nimi: string, kyselynro: number) {
  //   try {
  //     const response = await fetch(`/api/protected-zip/${y_tunnus}/${kyselynro}/`);
  //     if (response.ok) {
  //       const blob = await response.blob();
  //       const url = window.URL.createObjectURL(blob);
  //       const link = document.createElement("a");
  //       link.href = url;
  //       link.setAttribute("download", y_tunnus + " - " + zip_nimi);
  //       document.body.appendChild(link);
  //       link.click();
  //       link.parentNode?.removeChild(link);
  //     } else {
  //       // Handle error
  //       console.error("Error downloading zip file");
  //     }
  //   } catch (error) {
  //     console.error("Error downloading zip file:", error);
  //   }
  // }

  return (
    <div id="tilaajataulu">
      <Box
        sx={{
          backgroundColor: theme.palette.background.paper,
          pt: 14,
          pb: 13,
          px: 2,
        }}
      >
        {error ? ( // there is an error
          <Typography
            variant="h6"
            color="error"
            sx={{ mt: 10 }}
          >
            {error}
          </Typography>
        ) : data.length === 0 ? ( // there is no error, but the data length is 0
          <Typography
            variant="h6"
            sx={{ mt: 20 }}
          >
            Tietokannasta ei löydy tilausdataa.
          </Typography>
        ) : (
          // else, there is no error and the data length is > 0
          <TilaajatauluComponent data={data} otsikko="Tilaajat" />
        )}
      </Box>
    </div>
  );
};

export default Tilaajataulu;
